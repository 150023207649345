@import 'styles/includes.nordr.scss';

.CardExternalProject {
    display: block;
    text-decoration: none;

    @include media(lg) {
        display: flex;
    }

    &__Card {
        @include media(lg) {
            width: 65%;
        }
    }

    &__ShortFacts {
        position: relative;
        margin-top: -25px;
        padding: 0 20px;
        border-radius: 1px;

        @include media(lg) {
            display: flex;
            align-items: center;
            width: calc(35% + 25px);
            margin-top: 0;
            margin-left: -25px;
            padding: 25px 0;
        }
    }
}
