@import 'styles/includes.folkhem.scss';

.CardExternalProject {
    display: block;
    text-decoration: none;

    @include media(md) {
        display: flex;
    }

    &__Card {
        @include media(md) {
            flex-shrink: 0;
            width: calc(((100% / 3) * 2) - 8px);
        }
    }

    &__ShortFacts {
        @include media(sm) {
            flex-grow: 1;
        }
    }
}
